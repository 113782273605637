<template>
    <div style="background-color:#EFF1F2;height:100%;width:100%">
      <div style="padding-top:5% !important;">
        <v-img src="../assets/gimbook_logo.svg" style="max-width:200px; max-height:300px;" class="mx-auto"></v-img>
      </div>
      <v-card
        class="mx-auto  mt-7 "
        style="padding:50px 50px;min-width:250px;margin-top:6% !important;"
        max-width="580"
        height="400"
      >
      
      
        <v-card-text 
          class="text-center pt-3 pb-2 pt-2" 
          style="margin-bottom:50px;font-size:24px;text-align:center"
        >
      <p>
        <v-icon style="border-radius:20px;background-color:#1FAA59;color:white;text-align:center;" x-large class="icon" >mdi-check</v-icon>
      </p>     
          Password changed  successfully   
        </v-card-text>
       <v-btn  
        x-large
        dark
        type="submit"
        style="width:100%;margin:auto;font-size:22px;font-weight: 900;background-color:#363740;"
        @click="goToLogin()" 
        >
            Go To Login Page
      </v-btn>
       </v-card>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';

export default {
    name:"PasswordResetConfirm",
    methods:{
      goToLogin() {    this.$router.push({name:'Login'})} 
    }
}
</script>

<style scoped>

</style>